<template>
  <div class="u-wrapper-panel">
    <template v-for="(keypoint, index) in keypoints">
      <transition-group name="t-fader">
        <super-keypoint
          :key="keypoint.slug"
          :title="keypoint.title"
          :to="getParams(keypoint.slug)"
          :position="keypoint.position[0]"
          :index="index + 1"
          v-if="!transition"
        />
      </transition-group>
    </template>

    <template v-for="(tutorial, index) in tutorials">
      <transition name="t-fader--delayed">
        <popinTuto
          v-if="tutoIndex === index"
          @next="onTutoNext"
          :text="tutorial.text"
          :img="tutorial.image.length ? tutorial.image[0].url : ''"
        />
      </transition>
    </template>
    <template v-for="(tutorial, index) in tutorials">
      <transition name="t-fader--delayed">
        <indice-tuto
          v-if="tutoIndex === index"
          @next="tutoIndex++"
          :text="tutorial.text"
          :img="tutorial.image.length ? tutorial.image[0].url : ''"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import SuperKeypoint from '@/components/common/SuperKeypoint'

import Categories from '@/components/Categories'
import PopinTuto from '@/components/PopinTuto'
import IndiceTuto from '@/components/IndiceTuto'

import Page from './Page'

import { Box3, Vector3 } from 'three'

import { webGL } from '@/webGL/WebGL'

import gsap from 'gsap'

export default {
  components: {
    SuperKeypoint,
    Categories,
    PopinTuto,
    IndiceTuto
  },

  mixins: [Page],

  data () {
    return {
      transition: true,
      tutoIndex: -1
    }
  },

  computed: {
    config () {
      return this.$store.getters['pages/config']
    },
    keypoints () {
      return this.$store.getters['data/keypoints']
    },
    tutorials () {
      return this.$store.getters['pages/tutorials']
    },
    _position () {
      if (this.config.position.length) {
        return new Vector3(parseFloat(this.config.position[0].x), parseFloat(this.config.position[0].y), parseFloat(this.config.position[0].z))
      }
    },
    _look () {
      if (this.config.position.length) {
        return new Vector3(parseFloat(this.config.look[0].x), parseFloat(this.config.look[0].y), parseFloat(this.config.look[0].z))
      }
    }
  },

  mounted () {
    setTimeout(() => {
      if (webGL.renderer.bokehTarget) {
        const middle = this.getSceneCenter()

        gsap.to(webGL.renderer.bokehTarget, {
          x: middle.x,
          y: middle.y,
          z: middle.z,
          ease: 'power2.out',
          duration: 1
        })
      } else {
        webGL.renderer.bokehTarget = this.getSceneCenter()
      }
      webGL.renderer.bokehZone = 0.3
      webGL.renderer.maxBlur = 3.6
    }, 300);

    if (!this.$store.getters['global/isTutoSeen']) {
      this.tutoIndex = 0
      this.$store.commit('global/isTutoSeen', true)
    }
  },

  methods: {
    onTutoNext () {
      this.$ga.send('navigation', 'tutorial', 'next', this.tutoIndex)
      this.tutoIndex++

      if (this.tutoIndex >= this.tutorials.length) {
        this.$ga.send('navigation', 'tutorial', 'end')
      }
    },

    getParams (slug) {
      return {
        ...this.$local('Keypoint'),
        params: {
          super: slug
        }
      }
    },

    getSceneCenter () {
      const box = new Box3().makeEmpty()
      this.keypoints.forEach(keypoint => {
        const pos = new Vector3(keypoint.position[0].x, keypoint.position[0].y, keypoint.position[0].z)
        box.expandByPoint(pos)
      })

      return box.getCenter(new Vector3())
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.homepage
  &__categories
    position absolute
    top 0
    left $spacings.md
    height 100%
</style>