<template>
  <transition name="t-fader">
    <div v-if="$route.name !== 'Introduction' && isVisible" class="header u-between u-full-width u-row u-left u-top u-pad-t-sm u-pad-x-sm u-main-color u-pointer-none">
      <div class="u-row u-middle u-w7of12 u-pointer-all"> <!-- :class="$route.params.slug ? 'u-w9of12': 'u-w3of12'" -->
        <router-link class="header__logo u-block" :to="{name: 'Homepage'}">
          <img src="/images/logocognac.jpg" class="u-full-width u-block">
        </router-link>
        <transition name="t-fader">
          <router-link
            v-if="superKeypoint"
            :to="$route.params.slug ? {name: 'Keypoint', params: {super: this.$route.params.super}} : {name: 'Homepage'}"
            class="header__link u-row u-middle u-pad-x-md u-pad-x-sm--md u-block"
          >
            <app-button-round v-if="$device.isDesktop" :invert="true" svg="arrow-left" :small="true"/>
            <div v-if="!this.isMenuVisible" class="t-h3 u-white u-marg-l-sm u-marg-l-0--sm">
              {{ formatNumber(superKeypoint.index+1) }} - {{ superKeypoint.title }}
            </div>
          </router-link>
        </transition>
      </div>
      <div class="u-w5of12 u-row u-middle u-right u-pointer-all">
        <template v-if="!($device.isDesktop && $route.name?.includes('SubKeypoint'))">
          <app-button-round
            @click="$emit('navigation')"
            svg="zoom"
            :white="true"
          />
          <app-button-round
            v-if="!$device.isDesktop"
            @click="toggleMenu"
            :white="true"
            :svg="isMenuVisible ? 'cross' : 'menu'"
            :stroke="isMenuVisible ? false : true"
            class="u-marg-l-xs"
          />
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  },

  data () {
    return {
      isVisible: true,
      isMenuVisible: false
    }
  },

  computed: {
    superKeypoint () {
      return this.$store.getters['data/keypoint'](this.$route.params.super)
    },
    titleHomepage () {
      return this.$store.getters['pages/homepage'].title
    },
  },

  mounted () {
    this.$hub.on('article:scroll', res => {
      this.isVisible = !res
    })
  },

  methods: {
    formatNumber (nbr) {
      return nbr < 10 ? '0' + String(nbr) : String(nbr)
    },
    toggleMenu () {
      this.$emit('menu')
      this.isMenuVisible = !this.isMenuVisible
    }
  },

  watch: {
    $route () {
      this.isVisible = true
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.header
  position absolute
  top 0
  left 0

  & > *
    z-index 1

  &::after
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 130%
    background-image linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%)
    z-index 0
  
  &__link

    +mq($until: 'tablet')
      position absolute
      top 100px
      left 0
      

  &__logo
    width 100px

    +mq($until: 'tablet')
      width 55px
</style>