<template>
  <div>
    <component
      :is="to.name ? 'router-link' : 'div'"
      :to="to"
      class="keypoint u-cursor-pointer"
      :class="{'is-active': isVisible, 'is-complete': isComplete}"
      @click.stop="$emit('click')"
    >
      <div class="keypoint__item u-box-1by1">
        <div class="keypoint__item__main u-wrapper-panel" />
        <div class="keypoint__item__pulse" :class="{'is-active': !isSelected && !isComplete}"></div>
        <div class="keypoint__item__heart"></div>
      </div>
    </component>
  </div>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d],

  created () {
    this._camera = webGL.camera
  },

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    isComplete: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

width = 30px
halfWidth = 15px

.keypoint  
  opacity 0

  &.is-active
    opacity 1

  &__item
    top 0
    width width
    border-radius 50%
    left -1 * halfWidth
    transition opacity .3s linear
    
    &__main
      border-radius 50%
      background-color $colors.second-color

    &__heart
      position absolute
      background: $colors.second-color
      height 30%
      width 30%
      top 35%
      left 35%
      border-radius 50%

    &__pulse
      position absolute
      border solid 3px $colors.second-color
      height 200%
      width 200%
      top -50%
      left -50%
      border-radius 50%
      opacity 0

      &.is-active
        animation 2s ease-in-out-quad infinite pulse

  &.is-complete &__item
    &__main
      background-color:  $colors.second-color
    &__heart
      background-color: $colors.white


@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>