// import '@/mixins/extendedComponent'

import { ref } from 'vue'

import { webGL } from '@/webGL/WebGL'

import { engine, LoaderManager, GLTFLoader } from 'shimmer'

import { assets } from '@/assets/data'

export default {

  created () {
    engine.ready = true
    window.addEventListener('resize', this._onResize)
    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('keydown', this._onKeyDown)

    this.$device._onResize()
    this.$hub.emit('resize', event)

    LoaderManager.config = assets
    GLTFLoader.draco = { path: '/draco/' }
    GLTFLoader.ktx = { path: '/basis/', renderer: webGL.renderer.renderer }
  },

  mounted () {
    webGL.el = this.$refs.containerWebGL
    engine.el = this.$refs.containerWebGL

    this.$dom3d.onResize(this.$device.width, this.$device.height)
    this.$dom3d.attachTo(this.$refs.containerCSS3D)
    this.$dom3d.setCamera(webGL.camera)
    webGL.resize({ width: this.$device.width, height: this.$device.height })
    engine.camera = webGL.camera

    this._onResize()

    // window.addEventListener(this.$device.pointerdown, engine.pointerdown)
    // window.addEventListener(this.$device.pointermove, engine.pointermove)

    window.addEventListener(this.$device.pointerdown, this._onPointerdown)
    window.addEventListener(this.$device.pointermove, this._onPointermove)
  },

  methods: {
    onUpdate (time) {
      this._onUpdate(time)
    },

    _onUpdate ({ delta, elapsed }) {
      webGL.update()
      this.$dom3d.update()
      this.$device._onUpdate()
    },

    _onResize (event) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$device._onResize()
        this.$dom3d.onResize(this.$device.width, this.$device.height)
        webGL.resize({ width: this.$device.width, height: this.$device.height })
        this.$hub.emit('resize', event)
      }, 200)
    },

    _onScroll (event) {
      this.$device._onScroll()
      this.$hub.emit('scroll', event)
    },

    _onKeyDown (event) {
      this.$hub.emit('keydown', event.keyCode)
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.$hub.emit('keydown:escape', event.keyCode)
      }
    },

    _onPointerdown (event) {
      this.$device._onPointerMove(event)
      engine.pointerdown(event)
    },

    _onPointermove (event) {
      this.$device._onPointerMove(event)
      engine.pointermove(event)
    }
  }
}
