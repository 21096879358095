export const assets = [
  {
    name: "env",
    url: "/test.glb"
  }
]

export const setup = {
  mainColor: "#ff0000",
  secondColor: "#00ff00",
  duration: 2
}

export const scoring = {
  right: 10,
  wrong: -5
}

// more aperture it is,
// smaller is the focused zone
export const postProcess = {
  apertureMax: 3,
  useBokeh: true,
  useBloom: false
}

export const autoRenderLeveling = true

export const renderLevels = [
  ['NO_POSTPROCESS', 'NO_RETINA', 'OBJ_LOW', 'NO_SHADOW', 'RESOLUTION_LOW'],
  ['NO_POSTPROCESS', 'NO_RETINA', 'OBJ_LOW', 'NO_SHADOW'],
  ['NO_POSTPROCESS', 'NO_RETINA'],
  ['NO_POSTPROCESS'],
  []
]

export const annexes = [{
    name: 'about',
    model: 'stabout_stabout_Entry',
    type: 'stabout',
    active: true
  },
  {
    name: 'credits',
    model: 'stCredits_stCredits_Entry',
    type: 'stCredits',
    active: true
  },
  {
    name: 'cgu',
    model: 'stCGU_stCGU_Entry',
    type: 'stCGU',
    active: true
  },
  {
    name: 'personal',
    model: 'stPersonalData_stPersonalData_Entry',
    type: 'stPersonalData',
    active: true
  }
]