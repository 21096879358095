<template>
  <div class="u-wrapper-panel" @click="onClose">
    <transition :name="$device.isMobile ? 't-translate--up' : 't-translate--right'">
      <panel v-show="!transition" :data="data" :prev="prev" :next="next" />
    </transition>
  </div>
</template>

<script>
import { Vector3, Box3 } from 'three'

import Panel from '@/components/Panel'

export default {
  components: {
    // AppBackButton,
    Panel
  },

  props: {
    data: {
      type: Object,
      default: () => { return {} }
    },
    parent: {
      type: Object,
      default: () => { return {} }
    },
    transition: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      popinMedia: '',
      isPanelOpen: false
    }
  },

  mounted () {
    this.$ga.send('navigation', 'Keypoint', 'media', this.data.slug)
  },

  computed: {
    next () {
      let target = this.parent.keypoints[this.data.indexKeypoint+1]

      if (!target) {
        target = this.parent.keypoints[0]
        return {
          name: this.$route.name,
          params: {
            super: this.parent.slug,
            slug: target.slug
          }
        }
      }

      return {
        name: this.$route.name,
        params: {
          super: this.parent.slug,
          slug: target.slug
        }
      }
    },

    prev () {
      let target = this.parent.keypoints[this.data.indexKeypoint-1]

      if (!target) {
        target = this.parent.keypoints[this.parent.keypoints.length - 1]
        return {
          name: this.$route.name,
          params: {
            super: this.parent.slug,
            slug: target.slug
          }
        }
      }

      return {
        name: this.$route.name,
        params: {
          super: this.parent.slug,
          slug: target.slug
        }
      }
    }
  },

  mounted () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.isPanelOpen = false
      this.$store.commit('data/completed', { keypoint: this.data })
      // this.$store.commit('data/isSeen', {indexPlace: this.parent.index, indexKeypoint: this.data.index})
    },
  },

  watch: {
    $route () {
      this.onInit()
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint
  &__button
    position absolute
    top 50%
    left 30px
    height 80px
    width 80px
    padding 23px
    border-radius 50%
    
    &--right
      left auto
      // 450 + 30
      right 480px

</style>