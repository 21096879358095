import { BaseObjectSt } from '@/webGL/objects/BaseObjectSt'

import {
  DirectionalLight
} from 'three'

export class Environment extends BaseObjectSt {
  constructor (args) {
    super(args)
  }

  setupLight () {
    // this.ambient = new AmbientLight(0xffffff, .3)
    // this.add(this.ambient)


    // this.directional = new DirectionalLight(0xffffff, 1)
    // this.directional.position.set(-15, 15, 20)
    // this.directional.lookAt(0, 0, 0)
    // this.directional.castShadow = true
    // this.directional.shadow.bias = -0.0004
    // this.directional.shadow.camera.far = 70
    // this.directional.shadow.camera.near = 0.001
    // this.directional.shadow.mapSize.width = 1024
    // this.directional.shadow.mapSize.height = 1024
    // this.directional.shadow.camera.updateProjectionMatrix()
    // this.add(this.directional)
  }
}