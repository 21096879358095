<template>
  <div class="u-wrapper-panel u-row u-bottom u-center">
    <template v-for="(question, i) in data.questions">
      <transition name="t-fader--delayed">
        <div
          v-if="(isQuestion || $device.isDesktop) && currentQuestion === i"
          class="keypoint-question u-w4of12 u-w12of12--sm u-row u-middle  u-pad-y-md u-pad-r-md u-pad-l-md--sm"
        >
          <question @answer="onAnswer" @next="currentQuestion++" :data="question" />
        </div>
      </transition>
    </template>

    <transition name="t-fader">
      <app-button @click="isQuestion = true" v-if="!isQuestion && !$device.isDesktop" class="u-marg-b-md">
        Voir questions
      </app-button>
    </transition>
  </div>
</template>

<script>
import Question from '@/components/Question'
import AppButton from '@/components/common/AppButton'

export default {
  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    transition: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      currentQuestion: -1,
      isQuestion: false
    }
  },

  computed: {
    totalQuestions () {
      return this.$store.getters['data/totalQuestions']
    }
  },

  components: {
    Question
  },

  mounted () {
    this.onInit()
    this.$ga.send('navigation', 'Keypoint', 'quizz', this.data.slug)
  },

  methods: {
    onInit () {
      this.currentQuestion = 0
    },

    onAnswer ({ right, answers }) {
      if (!this.data.isCompleted) {
        if (right) {
          this.$store.commit('global/right')
        }
        else {
          this.$store.commit('global/wrong')
        }
      }

      this.$store.commit('data/completed', {
        keypoint: this.data,
        question: this.currentQuestion,
        answers: answers
      })
    },

    checkKeypointStatus () {
      if (!this.data.questions.filter(question => !question.isComplete).length) {
        this.$store.commit('data/completed', { keypoint: this.data })
      }
    }
  },

  watch: {
    currentQuestion () {
      if (this.currentQuestion >= this.data.questions.length) {
        // we mark this keypoint as completed
        this.$store.commit('data/completed', { keypoint: this.data })

        if (this.$route.name === 'SubKeypoint') {
          this.$router.push({ name: 'Keypoint', params: {super: this.$route.params.super }})
        }
        else {
          this.$router.push({ name: 'Homepage' })
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.keypoint-question
  position absolute
  right 0
  top 0
  height 100%
</style>