import { storage, cookie } from '@/utils/storage'
import { scoring } from '@/assets/data'

export default {
	namespaced: true,

	state: {
		score: 0,
		isFistVisit: true,
		sound: true,
		// isTutoSeen: cookie.getItem('tuto_seen') === 'true' ? true : false
		isTutoSeen: true
	},

	getters: {
		score (state) {
			return state.score
		},
		isTutoSeen(state) {
			return state.isTutoSeen
		}
	},

	mutations: {
		right (state) {
			state.score += scoring.right
		},
		wrong (state) {
			state.score += scoring.wrong
		},
    isTutoSeen (state, payload) {
      state.isTutoSeen = payload
      cookie.setItem('tuto_seen', payload)
    },
    sound (state, payload) {
      state.sound = payload
    }
	}
}