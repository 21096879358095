import { Vector3, Box3, CatmullRomCurve3, Line, LineBasicMaterial, BufferGeometry } from 'three'
import { webGL } from '@/webGL/WebGL'
import { FitTo, positionPadding } from 'shimmer'
import { setup } from '@/assets/data'

import { OrbitAuto } from 'shimmer'
// import { OrbitAuto } from '@/utils/tempOrbitAuto'

import gsap from 'gsap'

const transitionProgress = { progress: 0 }

const zoomDist = {
  min: .2,
  max: 5
}

export default {
  data () {
    return {
      transition: true
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/keypoint'](this.$route.params.super, this.$route.params.slug)
    },
    parent () {
      return this.$store.getters['data/keypoint'](this.$route.params.super)
    },
    hasChildren () {
      return !!this.data?.keypoints?.length
    },
    hasQuestions () {
      return !!this.data?.questions?.length
    }
  },

  mounted () {
    this.orbit = new OrbitAuto(webGL.camera, null, Math.PI * .1, Math.PI * .1, this.$device.isMobile)
    this.onInit()

  },
  
  methods: {
    onInit () {

      setTimeout(() => {

        if (this.$route.params.super && this.$route.params.slug) {
          webGL.renderer.bokehTarget = null
          webGL.renderer.bokehDist = 0.01
          webGL.renderer.bokehZone = 0.24
          webGL.renderer.maxBlur = 3.6
        } else {
          const middle = this.superKeypointCenter()
          if (webGL.renderer.bokehTarget) {
    
            gsap.to(webGL.renderer.bokehTarget, {
              x: middle.x,
              y: middle.y,
              z: middle.z,
              ease: 'power2.inOut',
              duration: 1.3
            })
          } else {
            webGL.renderer.bokehTarget = middle
          }
          webGL.renderer.bokehZone = 0.12
          webGL.renderer.maxBlur = 3.6
        }
      }, 300);

      this.transition = true

      this.orbit.active = false

      this.computeTransition()
    },

    computeTransition () {
      const positions = []
      const looks = []
      const dists = []
      positions.push(webGL.camera.position.clone())
      looks.push(webGL.camera.look.clone())
      // we use Vector3 fo dist as it will ease
      // the same way as positions and look
      dists.push(new Vector3(positions[0].distanceTo(looks[0])))

      this.data.stIntermediatePoint.forEach((point, index) => {
        positions.push(new Vector3(parseFloat(point.position[0].x), parseFloat(point.position[0].y), parseFloat(point.position[0].z)))
        looks.push(new Vector3(parseFloat(point.look[0].x), parseFloat(point.look[0].y), parseFloat(point.look[0].z)))
        // index + 1 is because we already add
        // base position and base look
        dists.push(new Vector3(positions[index + 1].distanceTo(looks[index + 1])))
      })

      if (this.hasChildren) {
        const { position, look } = this.superKeypointPlace()
        positions.push(position)
        looks.push(look)
        if (position.y > 100) this.$hub.emit('farPoi')
        else this.$hub.emit('closePoi')
      }
      else {
        const { position, look } = this.keypointPlace()
        positions.push(position)
        looks.push(look)
      }
      dists.push(new Vector3(positions[positions.length - 1].distanceTo(looks[looks.length - 1])))

      // not used right now
      this.trajectoryLength = 0
      positions.forEach((pos, index) => {
        if (index > 0) {
          this.trajectoryLength += pos.distanceTo(positions[index-1])
        }
      }, 0)

      this.cameraTrajectory = new CatmullRomCurve3(positions, false, 'centripetal')
      this.lookTrajectory = new CatmullRomCurve3(looks, false, 'centripetal')
      this.distTrajectory = new CatmullRomCurve3(dists, false, 'centripetal')
      this.transitionTo()
    },

    superKeypointCenter () {
      const box = new Box3().makeEmpty()
      this.data.keypoints.forEach(keypoint => {
        const pos = new Vector3(keypoint.position[0].x, keypoint.position[0].y, keypoint.position[0].z)
        box.expandByPoint(pos)
      })

      return box.getCenter(new Vector3())
    },

    superKeypointPlace () {
      const box = new Box3().makeEmpty()
      this.data.keypoints.forEach(keypoint => {
        const pos = new Vector3(keypoint.position[0].x + 20, keypoint.position[0].y, keypoint.position[0].z)
        box.expandByPoint(pos)
      })

      // if only one keypoint, we fake others keypoint 
      // right before and after
      // to prevent camera from behing stuck
      if (this.data.keypoints.length === 1) {
        const marg = .5
        box.expandByPoint(new Vector3(parseFloat(this.data.keypoints[0].position[0].x) - marg, parseFloat(this.data.keypoints[0].position[0].y) - marg, parseFloat(this.data.keypoints[0].position[0].z) - marg))
        box.expandByPoint(new Vector3(parseFloat(this.data.keypoints[0].position[0].x) + marg, parseFloat(this.data.keypoints[0].position[0].y) + marg, parseFloat(this.data.keypoints[0].position[0].z) + marg))
      }

      const look = new Vector3(this.data.look[0].x, this.data.look[0].y, this.data.look[0].z)
      return FitTo.fit( box, this.$device.isMobile ? .6 : 1.2, { vector: look }, 'contain' )
    },

    keypointPlace () {
      const basePosition = new Vector3(parseFloat(this.data.position[0].x), parseFloat(this.data.position[0].y), parseFloat(this.data.position[0].z))
      const baseLook = new Vector3(parseFloat(this.data.look[0].x), parseFloat(this.data.look[0].y), parseFloat(this.data.look[0].z))
      const dist = zoomDist.min + (100 - this.data.zoom) * (zoomDist.max - zoomDist.min) * .01

      return positionPadding(basePosition, baseLook, dist, this.$device.isMobile ? 0 : .20)
    },

    transitionTo () {
      // fast or firstScreen
      if (this.$route.query.fast || (webGL.camera.position.x === 0 && webGL.camera.position.y === 0 && webGL.camera.position.z === 0)) {
        const position = this.cameraTrajectory.getPoint(1)
        const look = this.lookTrajectory.getPoint(1)
        const dist = this.distTrajectory.getPoint(1)

        webGL.camera.position.copy(position)
        webGL.camera.look.copy(look)
        // webGL.renderer.bokehDist = dist.x
        // webGL.renderer.bokehDist = 0.1
        // webGL.renderer.bokehZone = 0.06
        // webGL.renderer.maxBlur = 3.6

        webGL.camera.forceUpdateLook = true
        this.transition = false
      }
      else {
        const duration = setup.duration + this.data.stIntermediatePoint.length * .8 * setup.duration
        this.tweenPosition = gsap.fromTo(transitionProgress, { progress: 0 }, {
          progress: 1,
          duration: setup.duration + this.data.stIntermediatePoint.length * .8 * setup.duration,
          ease: 'power2.inOut',
          onUpdate: () => {
            webGL.camera.forceUpdateLook = true
            const position = this.cameraTrajectory.getPoint(transitionProgress.progress)
            const look = this.lookTrajectory.getPoint(transitionProgress.progress)
            const dist = this.distTrajectory.getPoint(transitionProgress.progress)

            webGL.camera.position.copy(position)
            webGL.camera.look.copy(look)
            // webGL.renderer.bokehDist = 0.1
            // webGL.renderer.bokehZone = 0.006
            // webGL.renderer.maxBlur = 3.6
            webGL.camera.forceUpdateLook = true
          },
          onComplete: () => {
            setTimeout(() => {
              this.transition = false
              if (webGL.camera.position.y > 100) this.$hub.emit('farPoi')
              else this.$hub.emit('closePoi')
            }, 200)
          }
        })

        // this.tweenBokehZone = gsap.to(webGL.renderer, {
        //   bokehZone: 1,
        //   duration: duration,
        //   ease: 'power2.inOut'
        // })
      }
    },

    onUpdate () {
      if (this.orbit) {
        this.orbit.update(this.$device.pointer.smooth)
      }
    }
  },

  beforeUnmount () {
    if (this.tweenPosition) {
      this.tweenPosition.kill()
      // this.tweenBokehZone.kill()
    }

    this.orbit.onDestroy()
  },

  watch: {
    $route (to, from) {
      if (this.tweenPosition) {
        this.tweenPosition.kill()
        // this.tweenBokehZone.kill()
      }

      if (to.name === 'Keypoint' || to.name === 'SubKeypoint') {
        this.onInit()
      }
    },

    transition (to, from) {
      if(!this.$route.query.debug) {
        this.orbit.active = !this.transition
      }

      if (this.orbit.active) {
        this.$device.reinitSmoothPointer()
      }
    }
  }
}