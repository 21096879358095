<template>
  <div>
    <template v-if="$device.isDesktop && $route.name !== 'Introduction'">
      <div class="bottom-links u-middle u-between u-row t-h5 u-white">
        <div class="u-row u-left u-bg-second-color u-radius u-pad-y-xs u-pad-x-md">
          <div
            v-for="(page, index) in pages"
            @click="$emit('page', page.name)"
            class="bottom-links__link t-h5 u-uppercase t-bold u-cursor-pointer u-marg-r-md"
            :class="{'u-marg-r-md': index !== pages.length - 1}"
          >
             {{ page.title }}
          </div>
          <socials />
        </div>
        <!-- <div class="u-row u-bg-second-color u-radius u-pad-y-xs u-pad-x-md">
          <sound class="bottom-links__sound u-marg-l-sm" />
          <socials />
        </div> -->
      </div>
    </template>
    <!-- <template v-else-if="!$device.isDesktop && $route.name !== 'Introduction'">
      <div class="bottom-links u-row u-bottom u-right">
        <transition name="t-fader">
          <app-button-round v-if="$route.name === 'Homepage' " @click="isFilter = true" svg="filter" :invert="true" />
        </transition>
        <sound class="bottom-links__sound u-marg-l-sm" />
      </div>
    </template>
    <transition name="t-fader">
      <app-popin @close="isFilter = false" v-if="isFilter">
        <div class="u-bg-second-color u-radius u-pad-md u-relative">
          <categories />
        </div>
      </app-popin>
    </transition> -->
  </div>
</template>

<script>
import Socials from '@/components/common/Socials'
import Sound from '@/components/common/Sound'
import AppButtonRound from '@/components/common/AppButtonRound'
import AppPopin from '@/components/common/AppPopin'
import Categories from '@/components/Categories'

export default {
  computed: {
    pages () {
      return this.$store.getters['pages/annexes']
    }
  },

  data () {
    return {
      isFilter: false
    }
  },

  components: {
    Socials,
    AppPopin,
    AppButtonRound,
    Categories,
    Sound
  },

  watch: {
    $route () {
      this.isFilter = false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.bottom-links
  position absolute
  bottom: $spacings.md
  left: $spacings.md
  right: $spacings.md
  
  &__link
    transition transform .3s ease, opacity .3s linear
    &:hover
      transform scale(1.05)
      opacity .8
  
  &__sound
    width 20px
</style>