<template>
  <div
    class="t-button-quizz u-cursor-pointer u-center t-h3 u-relative"
    :class="{'is-true': isTrue, 'is-selected': isSelected, 'is-selecting': isSelecting, 'focus': focus}"
    @click="$emit('select')"
  >
    <input @focus="focus=true" @blur="focus=false" type="radio" :name="slugify(name)" :value="slug" :id="slug">
    <label :for="slug" class="t-button-quizz__content u-block u-relative">
      {{ label }}
    </label>
    <div class="t-button-quizz__over u-wrapper-panel u-white">
      {{ label }}
    </div>
  </div>
</template>

<script>
import Button from './Button'
import slugify from 'slugify'

export default {
  mixins: [Button],

  props: {
    isTrue: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isSelecting: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },

  computed: {
    slug () {
      return this.slugify(this.label)
    }
  },

  data () {
    return {
      focus: false
    }
  },

  mounted () {
    this.$el.addEventListener(this.$device.pointerdown, this.onPointerDown)
  },

  beforeUnmount () {
    this.$el.removeEventListener(this.$device.pointerdown, this.onPointerDown)
  },

  methods: {
    slugify (str) {
      return slugify(str).toLowerCase()
    },

    onPointerDown () {
      this.$emit('select')
    },
    onKeydown (event) {
      if (event === 13 && this.focus) {
        this.$emit('select')
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

padding = clamp(13px, 1.2em, 25px) clamp(20px, 1.4em, 40px)
radius = 20px
// duration = v-bind(durationString)
duration = .6s
colorTrue = #32B5A5
colorFalse = #FF7D6F

.t-button-quizz
  position relative
  color: $colors.black
  background-color transparent
  overflow hidden
  z-index 0
  border-radius radius
  background-color: $colors.white
  padding 0

  &.focus
    border: solid 3px $colors.black
  
  &:before, &:after
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius radius
    background-color: $colors.second-color
    transition: transform duration ease-out-quad, opacity duration linear
    opacity 0

  &:after
    background-color colorFalse

  input
    // visibility hidden
    height 0
    width 0
    position absolute

  &.is-true:after
    background-color colorTrue

  &__content
    padding: padding
    opacity 1
    z-index 1
    transition opacity duration linear

  &__over
    padding: padding
    opacity 0
    z-index 2
    transition opacity duration linear
  
  &.is-selecting:before
    opacity 1
    transform scale(1, 1) translateZ(0)

  &.is-selecting &
    &__content
      opacity 0
    &__over
      opacity 1
  
  &.is-selected:after
    opacity 1

  &.is-selected &
    &__content
      opacity 0
    &__over
      opacity 1

</style>