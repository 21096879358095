<template>
  <div class="result u-wrapper-panel u-row u-bottom u-center">
    <!-- <transition name="t-fader">
      <div v-if="!$device.isDesktop && !isDetails" class="result__title__wrapper u-center u-z-back">
        <div class="t-h3 u-main-color">
          Tu pourrais bien être...
        </div>
        <div class="u-center u-marg-t-sm u-pad-x-md">
          <h2 class="result__title t-h2 u-bg-main-color u-inline-block">
            {{ data.shortTitle ? data.shortTitle : data.title }}
          </h2>
        </div>
      </div>
    </transition> -->
    <transition name="t-fader">
      <div v-if="!isDetails && !$device.isDesktop" class="u-row u-full-width u-center u-marg-b-md">
        <app-button
          :second="true"
          v-if="data.CTAs[0]"
          :to="{ name: 'Homepage' }"
          @click="$ga.send('navigation', 'restart')"
          class="u-marg-r-sm"
        >
          RECOMMENCER
        </app-button>
        <app-button :main="true" @click="onDetails">
          VOIR RÉSULTAT
        </app-button>
      </div>
    </transition>

    <!-- MOBILE VERSION -->
    <transition name="t-fader--delayed">
      <app-popin
        innerClass="result__popin u-main-color u-radius u-pad-md u-row u-middle u-relative u-overflow-y-scroll"
        v-if="isDetails && !$device.isDesktop"
        :big="true"
      >
        <div @click="isDetails = false" class="result__popin__close">
          <svg class="u-wrapper-panel u-stroke-main-color">
            <use xlink:href="#cross"/>
          </svg>
        </div>
        <h2 class="t-h2 u-full-width">
          {{ data.title }}
        </h2>
        <strates-category :data="data.content" />
      </app-popin>
    </transition>

    <!-- DESKTOP VERSION -->
    <div v-if="$device.isDesktop">
      <div class="result__panel u-z-back u-w4of12 u-w12of12--sm u-row u-top u-pad-r-md">
        <div class="result__panel__item u-full-width u-radius u-main-color u-invisible-scroll">
          <div class="u-pad-md u-left">
            <h2 class="t-h2 u-full-width">
              {{ data.title }}
            </h2>
            <strates-category :data="data.content" />
          </div>
          <div class="result__panel__item__bottom u-row u-middle u-between u-radius">
            <app-button :to="{ name: 'Homepage' }" :second="true" @click="$ga.send('navigation', 'restart')">
              RECOMMENCER
            </app-button>
            <app-button :main="true" @click="onContact">
              Contactez nous
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import AppButton from '@/components/common/AppButton'
import StratesCategory from '@/components/StratesCategory'

import { audio } from '@/utils/Audio'

import Page from './Page'

import { Vector3 } from 'three'

export default {
  mixins: [Page],

  components: {
    AppPopin,
    AppButton,
    StratesCategory
  },

  computed: {
    data () {
      return this.$store.getters['data/mostChosenCategory']
    },
    page () {
      return this.$store.getters['pages/endpage']
    },
    videoId () {
      if (!this.data || !this.data.video.length) {
        return null
      }
      return this.$device.isMobile && this.data.video[1] ? this.data.video[1].url : this.data.video[0].url
    },

    _position () {
      if (this.page.position.length) {
        return new Vector3(parseFloat(this.page.position[0].x), parseFloat(this.page.position[0].y), parseFloat(this.page.position[0].z))
      }
    },
    _look () {
      if (this.page.position.length) {
        return new Vector3(parseFloat(this.page.look[0].x), parseFloat(this.page.look[0].y), parseFloat(this.page.look[0].z))
      }
    }
  },

  data () {
    return {
      isDetails: false
    }
  },

  mounted () {
    this.$ga.send('navigation', 'end')
    this.$ga.send('gameplay', 'result', '', this.data.slug)

    audio.src = this.$store.state.global.sounds.result
    audio.play()
  },

  methods: {
    onDetails () {
      this.$ga.send('navigation', 'end', 'details')
      this.isDetails = true
    },

    onContact () {
      console.log('plop')
    }
  },

  watch: {
    isDetails (to) {
      if (to) {
        this.$hub.emit('header:hidden')
      }
      else {
        this.$hub.emit('header:visible')
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

$second-color = #011449

.result
  &__panel    
    position absolute
    right 0
    top 100px
    bottom 100px

    &__item
      background-color: rgba($colors.white, .2)
      position relative
      max-height 100%
      overflow-y scroll
      
      &__bottom
        height 100px
        padding: 0 $spacings.md
        background-color: rgba($colors.white, .1)

  &__popin
    max-height 100%
    background-color: rgba($colors.white, .2)

    +mq($until: 'desktop')
      background-color: rgba($second-color, .5)

    &__close
      position absolute
      top 15px
      right 15px
      height 17px
      width 17px

  &__title
    border-radius 2em
    padding .7em 1em

    &__wrapper
      position absolute
      left 0
      top 20%
      width 100%
</style>