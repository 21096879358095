<template>
  <div>
    <component
      :is="to.name ? 'router-link' : 'div'"
      :to="to"
      class="super-keypoint u-cursor-pointer"
      @click.stop="$emit('click')"
    >
      <div class="t-h6 u-uppercase super-keypoint__title u-row u-left u-middle" v-if="title">
        {{ index }} - {{ title }}
      </div>
    </component>
  </div>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d],

  created () {
    this._camera = webGL.camera
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

width = 30px
halfWidth = 15px

.super-keypoint  
  opacity 1

  &__title
    border-radius 5px
    background: $colors.second-color
    color: $colors.white
    padding: $spacings.xs $spacings.sm

@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>