<template>
  <div class="socials u-row u-middle" :class="'u-' + display">
    <a
      v-for="(social, index) in socials"
      :href="social.url"
      class="socials__item"
      :class="{'u-marg-l-sm': index !== 0, 'is-squared': squared}"
    >
      <svg class="bottom-links__link u-full-width u-full-height u-fill-white" preserveAspectRatio="xMidYMid slice">
        <use :xlink:href="'#' + social.icon"/>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: String,
      default: 'evenly'
    },
    squared: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      socials: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/sharer/sharer.php?u=[[og:url]]'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: `https://twitter.com/share?url=[[og:url]]&text=${encodeURIComponent('[[og:description]]')}`
        },
        {
          name: 'Linkedin',
          icon: 'linkedin',
          url: 'https://linkedin.com/shareArticle/?url=[[og:url]]'
        }
      ]
    }
  }
}
  
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.socials
  &__item
    width 1.2em
    height 1.2em
    display block
    position relative

    &.is-squared
      width 4em
      height 4em
      padding 1em
      border solid 2px $colors.white
</style>