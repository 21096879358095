<template>
  <component
    :is="to.name ? 'router-link' : url !== '' ? 'a' : 'div'"
    :to="to"
    :href="url"
    :target="isUrlAbsolute ? '_blank' : ''"
    class="button-round u-inline-block u-relative u-round"
    :name="name"
    :class="{
      'is-big': big,
      'is-small': small,
      'is-ghost': ghost,
      'is-invert': invert,
      'is-white': white
    }"
  >
    <div class="u-box-1by1 u-cursor-pointer">
      <div class="button-round__wrapper u-wrapper-panel">
        <div class="u-full-width u-full-height u-relative">
          <svg
            class="u-full-width u-full-height"
            :class="stroke ? `u-stroke-${color}` : `u-fill-${color}`"
            preserveAspectRatio="xMidYMid meet"
          >
            <use :xlink:href="'#' + svg"/>
          </svg>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  computed: {
    color () {
      if (this.ghost && this.invert) {
        return 'second-color'
      }
      return this.white ? 'second-color' : 'white'
    }
  },

  props: {
    big: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    ghost: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: Boolean,
      default: false
    },
    invert: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    svg: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.button-round
  width 70px

  &:before
    position absolute
    content ''
    border-radius 5px
    top 0
    left 0
    height 100%
    width 100%
    background-color: $colors.main-color
    transition: transform .5s ease-in-out-quart

  &.is-ghost:before
    border: solid 2px $colors.white
    background-color: transparent
  &.is-ghost:focus:before
    border: solid 4px $colors.white

  &.is-ghost.is-invert:before
    border: solid 2px $colors.second-color
    background-color: transparent
  &.is-ghost.is-invert:focus:before
    border: solid 4px $colors.second-color

  &.is-ghost.is-white:before
    border: solid 2px $second-color
    background-color: transparent
  &.is-ghost.is-white:focus:before
    border: solid 4px $second-color

  &.is-white:before
    background-color: $colors.white
  &.is-white:focus:before
    border: solid 2px $colors.second-color

  &.is-invert:before
    background-color: $colors.second-color
  &.is-invert:focus:before
    border: solid 2px $colors.main-color
      

  +mq($until: 'tablet')
    width 50px
  
  &__wrapper
    padding 25%

    +mq($until: 'tablet')
      padding 30% 0

  &.is-big
    width 90px

  //   +mq($until: 'tablet')
  //     width 60px

  // &.is-big &__wrapper
  //   padding 27%

  &.is-small
    width 55px

    // +mq($until: 'tablet')
    //   width 60px

  // &.is-small &__wrapper
  //   padding 25%
  

    // +mq($until: 'tablet')
    //   padding 30%
  

+mq($from: 'tablet')
  .button-round:hover:before
    transform scale(1.1, 1.1)

</style>