import { api } from '@/store/interfaces/apiCraft'
// import Vue from 'vue'

let totalQuestions = 0

export default {
  namespaced: true,

  state: {
    keypoints: [],
    answerCategories: [],
    categories: [],
    loaded: false
  },

  getters: {
    keypoints: state => {
      return state.keypoints
    },
    keypoint: state => (arg1, arg2) => {
      const keypoint = state.keypoints.find(keypoint => keypoint.slug === arg1)

      if (keypoint && arg2) {
        return keypoint.keypoints.find(keypoint => keypoint.slug === arg2)
      }

      return keypoint
    },
    nextKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1)

      if (!arg2) {
        return state.keypoints[keypoint.index + 1] ? state.keypoints[keypoint.index + 1] : state.keypoints[0]
      }
      else {
        const subKeypoint = getters.keypoint(arg1, arg2)
        return keypoint.children[subKeypoint.index + 1] ? keypoint.children[subKeypoint.index + 1] : keypoint.children[0]
      }
    },
    prevKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1)

      if (!arg2) {
        return keypoint.index - 1 >= 0 ? state.keypoints[keypoint.index - 1] : state.keypoints[state.keypoints.length - 1]
      }
      else {
        const subKeypoint = getters.keypoint(arg1, arg2)
        return keypoint.children[subKeypoint.index + 1] ? keypoint.children[subKeypoint.index + 1] : keypoint.children[0]
      }
    },
    stupidNextKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1, arg2)
      return state.keypoints[keypoint.index + 1]
    },
    stupidPrevKeypoint: (state, getters) => (arg1, arg2) => {
      const keypoint = getters.keypoint(arg1, arg2)
      return state.keypoints[keypoint.index - 1]
    },
    categories: state => {
      return state.categories
    },
    totalKeypoints: state =>{
      let total = 0
      state.keypoints.forEach(k => {
        total += k.keypoints.length ? k.keypoints.length : 1
      })

      return total
    },
    totalCompleteKeypoints: state =>{
      let total = 0
      state.keypoints.forEach(k => {
        let completeChild = 0
        k.keypoints.forEach(key => {
          completeChild += key.isComplete ? 1 : 0
        })
        total += completeChild !== 0 ? completeChild : k.keypoints.isComplete ? 1 : 0
      })

      return total
    },
    totalQuestions: state  => {
      return state.totalQuestions
    },
    answerCategories: state => {
      return state.answerCategories
    },
    mostChosenCategory: state => {
      // first we create an object where property are cat ids
      let categories = classCategories(state.answerCategories)

      // we find the most voted cat
      let most = {id: '', nbr: -1}
      for (const cat in categories) {
        if (categories[cat] > most.nbr) {
          most.id = cat
          most.nbr = categories[cat]
        }
      }

      return state.answerCategories.find(cat => cat.id === most.id)
    },
    mostChosenCategories: state => {
      // first we create an object where property are cat ids
      let categories = classCategories(state.answerCategories)

      // we put it back as an array
      const catArray = []
      for (const cat in categories) {
        catArray.push({
          id: cat,
          nbr: categories[cat]
        })
      }

      catArray.sort((a, b) => {
        return a.nbr > b.nbr ? -1 : a.nbr < b.nbr ? 1 : 0
      })

      // return state.answerCategories.find(cat => cat.id === most.id)
      return catArray.map(catA => {
        return state.answerCategories.find(catB => catA.id === catB.id)
      })
    },
    loaded: state =>{
      return state.loaded
    }
  },

  actions: {
    keypoints ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getEntries()
        cache.then(res => {
          // if (res !== null) {
            commit('keypoints', formatKeypoints(res))
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('keypoints', formatKeypoints(res))
          commit('loaded', true)
          resolve()
        })
      })
    },

    categories ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getCategories()
        cache.then(res => {
          // if (res !== null) {
          //   commit('categories', res)
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          dispatch('catCount', res)
          resolve()
        })
      })
    },

    answerCategories ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getAnswerCategories()
        cache.then(res => {
          // if (res !== null) {
          //   commit('categories', res)
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('answerCategories', res)
          resolve()
        })
      })
    },

    catCount ({ commit, state }, categories) {
      let promises = []
      categories.forEach(cat => {
        const { request, cache } = api.getCategoryCount(cat.id)
        promises.push(
          new Promise(resolve => {
            // cache.then(res => {
            //   cat.count = res
            //   resolve()
            // })
            request.then(res => {
              cat.count = res
              resolve()
            })
          })
        )
      })

      return Promise.all(promises).then(() => {
        commit('categories', categories)
        // commit('loaded', true)
      })
    },

    filterKeypoints ({ commit, state }, categories) {
      return new Promise(resolve => {
        const { request, cache } = api.getEntriesFiltered(categories.map(cat => cat.id))
        cache.then(res => {
          // if (res !== null) {
          //   commit('keypointsFiltered', formatKeypoints(res))
          //   commit('loaded', true)
          // }
        })

        request.then(res => {
          commit('filter', res)
          // commit('loaded', true)
          resolve()
        })
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    keypoints (state, payload) {
      state.keypoints = payload
    },
    filter (state, payload) {
      const entries = payload.map(cat => cat.id)
      state.keypoints.forEach((keypoint, i) => {
        keypoint.keypoints.forEach((key, j) => {
          key.isActive = entries.includes(key.id)
        })
        keypoint.isActive = entries.includes(keypoint.id)
      })
    },
    categories (state, payload) {
      state.categories = payload
    },
    totalQuestions (state, payload) {
      state.totalQuestions = payload
    },
    answerCategories (state, payload) {
      state.answerCategories = payload
    },
    completed (state, args) {
      if (args.question !== undefined) {
        args.keypoint.questions[args.question].isComplete = true
        const catChosen = []
        args.answers.map(answer => answer.categories.map(cat => cat.id)).forEach(cat => {
          catChosen.push(...cat)
        }) // this is way too complicated
        args.keypoint.questions[args.question].catChosen = catChosen
      }
      else {
        args.keypoint.isComplete = true
      }
    }
  }
}

// We can do recursive stuff
// but for functionnal issues
// is is way more evolutive
// and easy no to
function formatKeypoints (keypoints) {
  let globalEpisodeIndex = 0
  let globalQuestionIndex = 0
  keypoints.forEach((keypoint, i) => {
    keypoint.keypoints.forEach((key, j) => {
      globalQuestionIndex = formatQuestions(key.questions, globalQuestionIndex)
      key.globalIndex = globalEpisodeIndex
      key.isComplete = false
      key.isActive = true
      key.indexKeypoint = j
      globalEpisodeIndex++
    })
    // keypoint.keypointsOnly = keypoint.keypoints.filter(keypoint => keypoint.typeHandle === 'keypoint')
    // keypoint.keypointsOnly.forEach((keypoint, index) => {
    //   keypoint.indexKeypoint = index
    //   total++
    // })
    globalQuestionIndex = formatQuestions(keypoint.questions, globalQuestionIndex)

    keypoint.index = i
    keypoint.isComplete = false
    keypoint.isActive = true
  })

  return keypoints
}

function formatQuestions (questions, globalIndex) {
  questions.forEach((question, k) => {
    question.answers.forEach((answer, l) => {
      answer.index = l
    })
    question.index = k
    question.globalIndex = globalIndex
    question.isComplete = false
    question.catChosen = []
    globalIndex++
  })

  totalQuestions = globalIndex > totalQuestions ? globalIndex : totalQuestions

  return globalIndex
}

function formatCategories (categories) {
  // categories.forEach(cat => {
  //   cat.nbrChosen = 0
  // })
  return categories
}

function classCategories (answerCat) {
  // first we create an object where property are cat ids
  let categories = answerCat.reduce((accumulator, current) => {
    accumulator[current.id] = 0
    return accumulator
  }, {})

  // in this object we increment values
  state.keypoints.forEach(keypoint => {
    keypoint.questions.forEach(question => {
      question.catChosen.forEach(cat => {
        categories[cat]++
      })
    })
  })
  state.keypoints.forEach(k => {
    k.keypoints.forEach(keypoint => {
      keypoint.questions.forEach(question => {
        question.catChosen.forEach(cat => {
          categories[cat]++
        })
      })
    })
  })

  return categories
}