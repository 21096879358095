<template>
  <div class="navigation u-wrapper-panel u-z-back u-row u-bg-white u-overflow-y-scroll u-invisible-scroll u-pad-y-sm--sm u-pad-x-sm--sm">
    <div class="u-row u-middle u-between u-marg-t-md u-marg-t-0--sm u-marg-b-lg u-full-width navigation__head u-bg-white">
      <div class="u-w1of12 u-center">
        <app-button-round class="navigation__close" @click="$emit('close')" svg="cross" />
      </div>
      <div v-if="$device.isDesktop" class="t-h2 u-main-color u-pad-r-sm u-w9of12">
        DÉCOUVREZ NOTRE [OBJET DE l’exprience]
      </div>
      <div class="u-w2of12 u-center u-flex">
        <div class="navigation__counter t-h3 t-bold u-main-color u-radius u-middle u-flex u-center">
          {{ totalComplete }}/{{ total }}
        </div>
      </div>
      <div v-if="!$device.isDesktop" class="t-h2 u-main-color u-pad-r-sm u-marg-t-md u-w12of12">
        DÉCOUVREZ NOTRE [OBJET DE l’exprience]
      </div>
    </div>
    <div class="u-left u-w11of12 u-marg-l-1of12 u-marg-l-0--sm u-w12of12--sm">
      <div v-for="superKeypoint in keypoints">
        <router-link :to="{name: 'Keypoint', params: { super: superKeypoint.slug }}" class="u-uppercase u-main-color u-marg-b-sm">
          <div class="t-h3 u-main-color t-black u-inline-block">
            {{superKeypoint.title}}
          </div>
          <div class="t-h5 u-inline-block navigation__countersmall u-radius u-marg-l-sm u-main-color">
            {{superKeypoint.keypoints.filter(keypoint => keypoint.isComplete).length}} / {{ superKeypoint.keypoints.length }}
          </div>
        </router-link>
        <div class="u-full-width u-overflow-x-scroll u-invisible-scroll u-marg-y-md">
          <div class="navigation__wrapper" :style="{width: (180 + 20) * superKeypoint.keypoints.length + 'px'}">
            <router-link
              v-for="keypoint in superKeypoint.keypoints"
              @click="onLink(keypoint.slug)"
              :to="{name: 'SubKeypoint', params: {super: superKeypoint.slug, slug: keypoint.slug}, query: {fast: true}}"
              class="navigation__card u-marg-r-sm u-bg-main-color u-radius u-overflow-hidden"
              :style="{opacity: keypoint.isComplete ? 1 : .5}"
            >
              <div class="u-box-4by3">
                <app-images v-if="keypoint.image.length" class="u-wrapper-panel u-fit-cover u-radius" :images="keypoint.image" />
              </div>
              <div class="t-h6 u-green u-center u-pad-b-sm u-pad-t-md u-pad-x-xs u-relative">
                {{ keypoint.title }}
                <img class="navigation__card__seen" src="/images/check.png">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'
import { webGL } from '@/webGL/WebGL'

export default {
  components: {
    AppButtonRound
  },

  computed: {
    keypoints () {
      return this.$store.getters['data/keypoints']
    },
    total () {
      return this.$store.getters['data/totalKeypoints']
    },
    totalComplete () {
      return this.$store.getters['data/totalCompleteKeypoints']
    }
  },

  mounted () {
    this.$ga.send('navigation', 'navigation', 'open')
  },

  beforeUnmount () {
    this.$ga.send('navigation', 'navigation', 'close')
  },

  methods: {
    onLink (slug) {
      this.$ga.send('navigation', 'menu', 'navigation', slug)
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.navigation
  &__head
    position sticky
    top 0
    z-index 1000
  &__close
    // position absolute
    // top: $spacings.md
    // left: $spacings.md
    
  &__counter
    // position absolute
    // top: $spacings.md
    // right: $spacings.md
    display inline-block
    border: solid 2px $colors.main-color
    padding 10px
    width 90px
    height 90px

    +mq($until: 'tablet')
      width 60px
      height 60px
  
  &__countersmall
    padding 4px 15px
    border: solid 2px $colors.main-color

  &__wrapper
    display flex
    flex-wrap nowrap

  &__card
    width 215px

    &__seen
      position absolute
      top -18px
      left calc(50% - 18px)
      width 36px
      height 36px

  &__close
    svg
      width 30px !important
</style>