<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
    :style="{
      '--main-color': mainColor,
      '--second-color': secondColor,
      '--viewport-height': $device.height + 'px'
    }"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view v-slot="{ Component }">
      <transition name="t-fader--delayed">
        <component v-if="loaded" :is="Component" />
      </transition>
    </router-view>

    <bottom-links @page="onPage" />
    <app-header @navigation="isNavigation = true" @menu="isMenu = !isMenu" />

    <transition name="t-fader">
      <menu-mobile @navigation="isNavigation = true" @page="onPage" v-if="isMenu" @close="isMenu = false" />
    </transition>

    <transition name="t-fader">
      <base-page :name="currentPage" v-if="currentPage !== ''" @close="currentPage = ''" />
    </transition>

    <transition name="t-fader">
      <navigation v-if="isNavigation" @close="isNavigation = false" />
    </transition>

    <debugger v-if="$route.query.debug" />

    <cookie />

    <transition name="transition" duration="1500">
      <transition-screen v-if="isTransition" />
    </transition>
    <transition name="custom" duration="2000">
      <app-loader v-if="!loaded" />
    </transition>

    <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden u-pointer-none css3d"/>
  </div>
</template>

<script>
import { LoaderManager, pointer } from 'shimmer'

import App from './App'

import { webGL } from '@/webGL/WebGL'

import AppHeader from '@/components/common/AppHeader'
import BottomLinks from '@/components/common/BottomLinks'
import MenuMobile from '@/components/common/MenuMobile'
import AppLoader from '@/components/common/Loader'
import TransitionScreen from '@/components/common/TransitionScreen'
import Debugger from '@/components/Debugger'

import Cookie from '@/components/common/cookie'

import BasePage from '@/views/BasePage'
import Navigation from '@/views/Navigation'
import SymbolsSvg from '@/components/SymbolsSvg'

import { Environment } from '@/webGL/objects/Environment'

import { Audio } from '@/utils/Audio'

import { levelFPS } from '@/utils/LevelFPS'

import {
  AmbientLight,
  SphereBufferGeometry,
  MeshNormalMaterial,
  Mesh
} from 'three'

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    AppHeader,
    BottomLinks,
    Navigation,
    MenuMobile,
    Cookie,
    AppLoader,
    BasePage,
    Debugger,
    TransitionScreen
  },

  data () {
    return {
      isAbout: false,
      isNavigation: false,
      isMenu: false,
      isEnvLoaded: false,
      isTransition: false,
      currentPage: ''
    }
  },

  computed: {
    mainColor () {
      return '#215240'
    },
    secondColor () {
      return '#B43C25'
    },
    config () {
      return this.$store.getters['pages/config']
    },
    loaded () {
      return this.$store.getters['pages/loaded'] && this.$store.getters['data/loaded'] && this.isEnvLoaded
    }
  },

  created () {
    this.pointerevent = null

    this.$store.dispatch('pages/load').then(this.onInit)
    this.$store.dispatch('data/keypoints')
    this.$store.dispatch('data/answerCategories')
    this.$store.dispatch('data/categories')
  },

  mounted () {
    this.onResize()

    this.$hub.on('transition:on', () => {
      this.isTransition = true
    })

    this.$hub.on('transition:off', () => {
      this.isTransition = false
    })

    this.$hub.on('navigation', () => {
      this.isNavigation = true
    })
  },

  beforeUnmount () {
    if (this.$route.query.debug) {
      webGL.camera.position.set(0, 0, 3)
    }
  },

  methods: {
    onInit () {
      this.env = new Environment({ url: this.config.object3d[0].url, urlLow: this.config.object3dLow[0]?.url })
      this.env.promise.then(() => {
        this.isEnvLoaded = true
        levelFPS.active = true
      })
      webGL.scene.add(this.env)

      // for (let i = 0 ; i < 10; i++) {
      //   const env = new Environment({ url: this.config.object3d[0].url })
      //   webGL.scene.add(env)
      // }

      if (this.$store.getters['pages/sounds'].background) {
        this.audio = new Audio()
        this.audio.player.loop = true
        this.audio.player.volume = .3
        this.audio.src = this.$store.getters['pages/sounds'].background
        this.$hub.on('audio:background', this.audio.play)
      }

      window.webGL = webGL
    },

    onPage (name) {
      this.currentPage = name
    },

    onUpdate (time) {
      this._onUpdate(time)
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .2px
    bottom .2px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>