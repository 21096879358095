<template>
  <transition name="t-fader">
    <template v-if="hasChildren">
      <kview :transition="transition" :slug="data.slug" :look="data.look[0]" :keypoints="data.keypoints" />
    </template>
    <template v-else>
      <question :transition="transition" :parent="parent" v-if="hasQuestions" :data="data" />
      <media :transition="transition" :parent="parent" v-else :data="data" />
    </template>
  </transition>
</template>

<script>
import Kview from '@/views/keypoint/View'
import Media from '@/views/keypoint/Media'
import Question from '@/views/keypoint/Question'

import Keypoint from './Keypoint.js'

export default {
  components: {
    Kview,
    Media,
    Question
  },

  mixins: [ Keypoint ],

  computed: {
    type () {
      return this.data.questions.length ? 'question' : 'media'
    }
  }
}
</script>