import { Component, Loader } from 'shimmer'

import { levelFPS } from '@/utils/LevelFPS'
import { emitter } from '@/utils/emitter'

import { AmbientLight, DirectionalLight } from 'three'

export class BaseObjectSt extends Component {
  constructor ({ url, urlLow, name }) {
    super()

    this.url = url
    this.urlLow = urlLow
    this.obj = null

    this.onLoad = this.onLoad.bind(this)
    this.onInit = this.onInit.bind(this)

    this.onInit()

    // this.promise = LoaderManager.load('building', false, obj => {
    //   obj.castShadow = true
    //   obj.receiveShadow = true
    // })

    this.setupLight()

    emitter.on('level:change', this.onInit)
  }

  onInit () {
    const url = this.urlLow && levelFPS.levelProperty.includes('OBJ_LOW') ? this.urlLow : this.url

    this.promise = Loader.load(url)
    this.promise.then(this.onLoad)
  }

  setupLight () {
    // this.ambient = new AmbientLight(0xffffff, .3)
    // this.add(this.ambient)


    // this.directional = new DirectionalLight(0xffffff, 1)
    // this.directional.position.set(-15, 15, 20)
    // this.directional.lookAt(0, 0, 0)
    // this.directional.castShadow = true
    // this.directional.shadow.bias = -0.0004
    // this.directional.shadow.camera.far = 70
    // this.directional.shadow.camera.near = 0.001
    // this.directional.shadow.mapSize.width = 1024
    // this.directional.shadow.mapSize.height = 1024
    // this.directional.shadow.camera.updateProjectionMatrix()
    // this.add(this.directional)
  }

  onLoad (obj) {
    if (this.obj !== null) {
      this.remove(this.obj)
      this.obj.traverse(child => {
        if (child.material) child.material.dispose()
        if (child.geometry) child.geometry.dispose()
      })
      this.obj = null
    }
    
    this.obj = obj.scene

    this.add(this.obj)
  }
}