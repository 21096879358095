<template>
  <div>
    <b>Programs</b>: {{ renderer.programs }}
    <b>Geometries</b>: {{ renderer.geometries }}
    <b>Textures</b>: {{ renderer.textures }}
    <b>Drawcalls</b>: {{ renderer.drawcalls }}
    <b>Triangles</b>: {{ renderer.triangles }} <br />

    <b>FPS Meter</b>: {{ fpsMeter }} <b>FPS Average</b>: {{ fpsAverage.toFixed(1) }}<br /><br />
    Render levels:
    <div
      v-for="(level, index) in levels"
      :style="{fontWeight: index === currentLevel ? 700 : 400}"
      @click="onLevel(index)"
      class="u-cursor-pointer"
    >
      {{index}}:
      <span v-if="level.length" v-for="rule in level" class="u-marg-r-xs">{{ rule }}</span>
      <span v-else>ALL</span>
    </div>
  </div>
</template>

<script>
import { renderLevels } from '@/assets/data'
import { levelFPS } from '@/utils/LevelFPS'
import { webGL } from '@/webGL/WebGL'

export default {
  data () {
    return {
      fpsMeter: 0,
      fpsAverage: 0,
      currentLevel: 0,
      levels: renderLevels,
      renderer: {
        programs: webGL.renderer.renderer.info.programs.length,
        geometries: webGL.renderer.renderer.info.memory.geometries,
        textures: webGL.renderer.renderer.info.memory.textures,
        drawcalls: webGL.renderer.renderer.info.render.calls,
        triangles: webGL.renderer.renderer.info.render.triangles
      }
    }
  },

  methods: {
    onUpdate ({ delta }) {
      this.fpsMeter = delta
      this.fpsAverage = levelFPS.average
      this.currentLevel = levelFPS.level

      this.renderer.programs = webGL.renderer.renderer.info.programs.length
      this.renderer.geometries = webGL.renderer.renderer.info.memory.geometries
      this.renderer.textures = webGL.renderer.renderer.info.memory.textures
      this.renderer.drawcalls = webGL.renderer.renderer.info.render.calls
      this.renderer.triangles = webGL.renderer.renderer.info.render.triangles
    },

    onLevel (level) {
      levelFPS.level = level
    }
  }
}
</script>