<template>
  <div class="page u-wrapper-panel u-white u-bg-main-color u-pad-x-1of12 u-pad-t-xl u-pad-t-sm--sm u-pad-x-sm--sm u-invisible-scroll u-overflow-y-scroll u-z-middle">
    <div class="u-relative">
      <div class="t-h1 u-marg-b-sm u-pad-t-lg--sm">{{ data.title }}</div>
      <div class="page__close">
        <app-button-round aria-label="close" @click="$emit('close')" class="u-marg-r-sm u-fill-white" svg="cross" :stroke="true" :ghost="true" />
      </div>

      <div class="u-row">
        <div class="u-w6of12 u-w12of12--sm">
          <div class="u-marg-b-md" v-if="name === 'personal'">
            <app-button @click="$hub.emit('cookie:open')">
              Manager préférence cookies
            </app-button>
          </div>
          <div class="t-wysiwyg u-white" v-html="data.wysiwyg" />
        </div>
        <div v-if="!$device.isMobile && data.image.length" class="u-black u-w4of12 u-marg-l-2of12 u-marg-t-xl">
          <app-images :images="data.image" class="u-block u-full-width" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound,
  },

  props: {
    name: {
      type: String,
      required: true
    }
  },

  mounted () {
    // this.$ga.send('navigation', 'page')
  },

  computed: {
    data () {
      return this.$store.getters['pages/annexe'](this.name)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.page
  &__close
    position absolute
    top 0
    right 0
</style>