<template>
  <div class="keypoint-view u-wrapper-panel">
    <!-- <keypoint-switch class="keypoint-view__switch" /> -->
    <template v-for="(keypoint, index) in keypoints">
      <keypoint
        :key="keypoint.slug"
        :to="getParams(keypoint.slug)"
        :position="keypoint.position[0]"
        :isComplete="keypoint.isComplete"
        v-if="keypoint.isActive"
      />
    </template>
    <app-button v-if="$device.isMobile" :to="{name: 'Homepage'}" class="keypoint-view__previous" :icon="true">
      <svg class="u-marg-r-sm">
        <use xlink:href="#arrow-left"/>
      </svg>
      Revenir en arrière
    </app-button>
  </div>
</template>

<script>
import Keypoint from '@/components/common/Keypoint'
import KeypointSwitch from '@/components/common/KeypointSwitch'
import AppButton from '@/components/common/AppButton'

export default {
  props: {
    keypoints: {
      type: Object,
      default: () => {return {}}
    },
    slug: {
      type: String,
      default: ''
    },
    transition: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Keypoint,
    KeypointSwitch
  },

  mounted () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.$ga.send('navigation', 'Keypoint', 'view', this.slug)
    },

    getParams (slug) {
      if (this.$route.params.super) {
        return {
          ...this.$local('SubKeypoint'),
          params: {
            super: this.$route.params.super,
            slug: slug
          }
        }
      }
      else {
        return {
          ...this.$local('Keypoint'),
          params: {
            slug: slug
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.keypoint-view
  &__previous
    position absolute
    bottom $spacings.sm
    left 20%
    width 60%
</style>