import { OrbitAuto } from 'shimmer'

import { webGL } from '@/webGL/WebGL'

import { setup } from '@/assets/data'

import gsap from 'gsap'

export default {
  data () {
    return {
      transition: true
    }
  },

  created () {
    this._onInit()
  },

  methods: {
    _onInit () {
      this._orbit = new OrbitAuto(webGL.camera, null, Math.PI * .05, Math.PI * .05)
      this._orbit.active = false

      const dist = this._position.distanceTo(this._look)
      const bokehZone = .5

      if (webGL.camera.position.x === 0 && webGL.camera.position.y === 0 &&webGL.camera.position.z === 0) {
        webGL.camera.position.copy(this._position)
        webGL.camera.look.copy(this._look)

        // webGL.renderer.bokehDist = dist
        webGL.renderer.bokehZone = bokehZone

        webGL.camera.forceUpdateLook = true
        requestAnimationFrame(() => {
          this.transition = false
        })
      }
      else {
        this.tweenPosition = gsap.to(webGL.camera.position, {
          x: this._position.x,
          y: this._position.y,
          z: this._position.z,
          duration: setup.duration,
          ease: 'power2.inOut',
          onComplete: () => {
            this.transition = false
          }
        })

        this.tweenLook = gsap.to(webGL.camera.look, {
          x: this._look.x,
          y: this._look.y,
          z: this._look.z,
          duration: setup.duration,
          ease: 'power2.inOut',
          onUpdate: () => {
            webGL.camera.forceUpdateLook = true
          }
        })

        // this.tweenBokehDist = gsap.to(webGL.renderer, {
        //   bokehDist: dist,
        //   duration: setup.duration,
        //   ease: 'power2.inOut'
        // })

        // this.tweenBokehZone = gsap.to(webGL.renderer, {
        //   bokehZone: bokehZone,
        //   duration: setup.duration,
        //   ease: 'power2.inOut'
        // })
      }
    },

    _onUpdate() {
      if (this._orbit) {
        this._orbit.update(this.$device.pointer.smooth)
      }
    },

    onUpdate () {
      this._onUpdate()
    }
  },

  beforeUnmount () {
    if (this.tweenPosition) {
      this.tweenPosition.kill()
      this.tweenLook.kill()
      // this.tweenBokehDist.kill()
      // this.tweenBokehZone.kill()
    }
    this._orbit.onDestroy()
  },

  watch: {
    $route (to, from) {
      if (to.name === from.name) {
        this._onInit()
      }
    },

    transition (to, from) {
      if(!this.$route.query.debug) {
        this._orbit.active = !this.transition
      }

      if (this._orbit.active) {
        this.$device.reinitSmoothPointer()
      }
    }
  }
}