<template>
  <div class="u-full-height u-full-width">
    <div
      @click.stop="() => {}"
      class="panel u-z-zero u-cursor-default u-w4of12 u-w12of12--md js-wrapper u-pad-md u-pad-0--md"
      :class="{'u-overflow-y-scroll u-invisible-scroll': $device.isTablet}"
      ref="panel"
    >
      <div class="panel__wrapper u-radius u-pad-t-md u-bg-main-color u-relative" :class="{'u-overflow-y-scroll u-invisible-scroll': !$device.isTablet}">
        <!-- PANEL HEADER -->
        <app-button @click="onClickArrow" v-if="$device.isTablet" class="panel__wrapper__cta">
          Voir plus
        </app-button>
        <div class="u-full-height u-relative">
          <div class="panel__top u-row u-middle u-pad-b-md u-pad-b-0--sm js-top">
            <div class="u-pad-l-md u-w3of12 u-w4of12--sm">
              <div class="u-row u-between">
                <router-link v-if="prev" :to="prev" class="panel__button u-w4of12 u-cursor-pointer">
                  <div class="u-full-width u-full-height">
                    <svg class="u-fit-contain" >
                      <use xlink:href="#arrow-left"/>
                    </svg>
                  </div>
                </router-link>
                <router-link v-if="next" :to="next" class="panel__button u-w4of12 u-cursor-pointer">
                  <div class="u-full-width u-full-height">
                    <svg class="u-fit-contain" >
                      <use xlink:href="#arrow-right"/>
                    </svg>
                  </div>
                </router-link>
              </div>
            </div>
            <div v-if="!$device.isMobile" class="u-w9of12 u-right u-pad-r-md">
              <app-button-round
                :to="{ name: 'Homepage' }"
                svg="home"
                :white="true"
                class="u-marg-r-sm"
                v-if="$route.params.slug"
              />
              <app-button-round
                @click="$hub.emit('navigation')"
                svg="zoom"
                :white="true"
              />              
            </div>
            <div class="u-full-width u-pad-x-md u-marg-t-md u-overflow-auto u-invisible-scroll t-h6 u-uppercase">
              <div class="panel__tag-wrapper u-flex u-row u-no-wrap">
                <span class="panel_tag u-pad-x-xs u-pad-y-xxs u-bg-second-color u-radius u-middle u-flex u-marg-r-xs" v-for="(tag, index) in data.categories" :key="index">
                  <img v-if="tag.image[0]" class="u-marg-r-xs u-marg-y-xxs" :src="tag.image[0].url" :alt="tag.image[0].alt">
                  {{ tag.title }}
                </span>
              </div>
            </div>
          </div>
          <!-- PANEL CONTENT -->
          <div
            class="panel__content u-marg-t-sm--md u-pad-b-sm--sm"
          >
            <app-article @full="isFull = true" :isFull="false" :data="data" />
          </div>
        </div>
      </div>
    </div>
    <transition name="t-fader">
      <app-popin class="u-pad-0--sm" innerClass="u-full-height u-w12of12--sm" v-if="isFull" :big="true" @close="isFull = false">
        <app-article :isFull="true" @close="isFull = false" :data="data" />
      </app-popin>
    </transition>
  </div>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

import AppArticle from '@/components/AppArticle'

import gsap from 'gsap'

export default {
  components: {
    AppButtonRound,
    AppArticle,
    AppButton,
    AppPopin
  },

  data () {
    return {
      isScolled: false,
      isFull: false
    }
  },

  props: {
    data : {
      type: Object,
      default: () => { return {} }
    },
    prev: {
      type: Object,
      default: () => { return {} }      
    },
    next: {
      type: Object,
      default: () => { return {} }      
    },
    tags: {
      type: Array,
      default: () => { return [
        {
          name: 'Tags courts',
          picto: 'https://api.iconify.design/ic:baseline-add-reaction.svg?color=%fdf2e4'
        },
        {
          name: 'Tags moyens',
          picto: 'https://api.iconify.design/ic:baseline-add-reaction.svg?color=%fdf2e4'
        },
        {
          name: 'Tags courts',
          picto: 'https://api.iconify.design/ic:baseline-add-reaction.svg?color=%fdf2e4'
        },
        {
          name: 'Marketing',
          picto: 'https://api.iconify.design/ic:baseline-add-reaction.svg?color=%fdf2e4'
        }
      ] }
    }
  },

  mounted () {
    if (this.$device.isTablet) {
      this.wrapper = this.$el.querySelector('.js-wrapper')
      this.titles = this.$el.querySelector('.js-titles')
      this.topPart = this.$el.querySelector('.js-top')
      this.$refs.panel.addEventListener('scroll', this.onScroll)
    }

    this.onResize()

    setInterval(this.onResize, 300)
  },

  methods: {
    onResize () {
      if (this.wrapper && this.titles) {
        this.wrapper.style.paddingTop = (this.$device.height - this.titles.offsetHeight - this.topPart.offsetHeight - 50) + 'px'
      }
    },

    onScroll () {
      const scrollTop = this.$refs.panel.scrollTop

      if (scrollTop > 0 && scrollTop > this.$device.height - 600) {
        this.isScolled = true
      }
      else {
        this.isScolled = false
      }
    },

    onClickArrow () {
      const scroll = {top: this.$refs.panel.scrollTop}

      if (this.isScolled) {
        gsap.to(scroll, {
          top: 0,
          duration: 1,
          ease: 'power3.inOut',
          onUpdate: value => {
            this.$refs.panel.scrollTo(0, scroll.top)
          }
        })
      }
      else {
        gsap.to(scroll, {
          top: this.$device.height - 200,
          duration: 1,
          ease: 'power3.inOut',
          onUpdate: value => {
            this.$refs.panel.scrollTo(0, scroll.top)
          }
        })
      }
    }
  },

  watch: {
    isScolled () {
      this.$hub.emit('article:scroll', this.isScolled)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.panel
  position absolute
  right 0
  top 0
  height 100%
  width 450px
  z-index 1

  &__title
    +mq($until: 'tablet')
      height 60px

  &__wrapper
    transition transform 1s ease-in-out-quart
    // +mq($until: 'tablet')
    //   border-radius 15px 15px 0 0
    +mq($from: 'tablet')
      height 100%

    &__cta
      position absolute
      top 0
      left 50%
      transform translate(-50%, -50%)

  &__tag-wrapper
    width: max-content

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    padding-top calc( 100vh - 200px )

  &__more
    padding 7px 20px
    position absolute
    top 0
    left 50%
    transform translate(-50%, -50%)

  &__content
    +mq($from: 'tablet')
      height calc(100% - 120px)

  &__arrow
    height 60px
    width 60px
    position absolute
    top -30px
    left calc( 50% - 30px )
    padding 15px 15px 12px
    transform scale(1, -1)
    transition transform .5s ease-in-out-quart
    
    &.is-open
      transform scale(1, 1)

  &__button
    height 45px
    width 45px
    padding 0
    border-radius 50%

    
</style>