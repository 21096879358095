import {
  WebGLRenderer,
  sRGBEncoding,
  PCFSoftShadowMap,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
  WebGLRenderTarget,
  LinearFilter,
  RGBAFormat
} from 'three'

// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

// import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'

import { DepthOfFieldEffect, EffectComposer, EffectPass, PredicationMode, RenderPass, SMAAEffect, SMAAPreset } from 'postprocessing'

import { postProcess as dataPostProcess } from '@/assets/data'

import { levelFPS } from '@/utils/LevelFPS'

const apertureFactor = .00001

import { gui, guiObj } from '@/utils/gui'

export class Renderer {
  constructor(scene, camera) {
    this.scene = scene
    this.camera = camera

    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)
    this.matChanger = this.matChanger.bind(this)
    this.usePostprocess = this.usePostprocess.bind(this)
    this.renderQuality = this.renderQuality.bind(this)

    this.useShadow = false

    this.setInstance() 
    this.usePostprocess()
    this.setPostProcess()
  }

  renderQuality () {
    this.usePostprocess()
    this.renderer.shadowMap.enabled = this.useShadow && !levelFPS.levelProperty.includes('NO_SHADOW')
  }

  usePostprocess () {
    this.isPostprocess = (dataPostProcess.useBokeh || dataPostProcess.useBloom) && !levelFPS.levelProperty.includes('NO_POSTPROCESS')

    if (!this.isPostprocess) {
      this.renderer.toneMappingExposure = 1.3
    }
    else if (dataPostProcess.useBokeh) {
      this.renderer.toneMappingExposure = 1.3
    }
    else if (dataPostProcess.useBloom) {
      this.renderer.toneMappingExposure = .5
    }
  }

  setInstance () {
    this.clearColor = '#88bce7'

    // Renderer
    this.renderer = new WebGLRenderer({
      alpha: false,
      antialias: true,
      stencil: false,
      preserveDrawingBuffer: false,
      powerPreference: 'high-performance',
    })
    this.renderer.setClearColor(this.clearColor)

    // this.renderer.physicallyCorrectLights = true
    this.renderer.outputEncoding = sRGBEncoding
    this.renderer.shadowMap.enabled = this.useShadow
    // this.renderer.shadowMap.type = PCFSoftShadowMap
    // this.renderer.toneMapping = ReinhardToneMapping
    // this.renderer.toneMapping = ACESFilmicToneMapping
    // this.renderer.gammaOutPut = true
    // this.renderer.gammaFactor = 2.2

    this.context = this.renderer.getContext()

    // Add stats panel
    if (this.stats) {
      this.stats.setRenderPanel(this.context)
    }
  }

  setPostProcess () {

    this.postProcess = {}

    /**
     * Render pass
     */
    this.postProcess.renderPass = new RenderPass(this.scene, this.camera)

    /**
     * Bokeh Pass
     */
    // this.postProcess.bokehPass = new BokehPass(this.scene, this.camera, {
    //   focus: 9,
    //   aperture: dataPostProcess.apertureMax * apertureFactor,
    //   maxblur: 50
    // })

    this.postProcess.dofEffect = new DepthOfFieldEffect(this.camera, {
      bokehScale: 3.6,
      focusDistance: 0.18,
      focalLength: 0.1
      // height: 480
    })
    
    this.postProcess.dofPass = new EffectPass(this.camera, this.postProcess.dofEffect)

    /**
     * BloomPass Pass
     * Resolution
     * strength
     * radius
     * threshold
     */
    this.postProcess.bloomPass = new UnrealBloomPass({x: 256, y: 256}, 1, 0, 0)

    /**
     * Effect composer
     */
    // const RenderTargetClass = WebGLRenderTarget
    this.renderTarget = new WebGLRenderTarget( window.innerWidth, window.innerHeight, {
        generateMipmaps: false,
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBAFormat,
        encoding: sRGBEncoding,
      }
    )
    this.postProcess.composer = new EffectComposer(this.renderer)
    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight)
    // this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.postProcess.composer.addPass(this.postProcess.renderPass)

    if (dataPostProcess.useBokeh) {
      this.postProcess.composer.addPass(this.postProcess.dofPass)
    }
    if (dataPostProcess.useBloom) {
      this.postProcess.composer.addPass(this.postProcess.bloomPass)
    }

    this.addGui()
  }

  addGui () {
    const folder = gui.addFolder('Bokeh')

    gui.add( guiObj, 'bokehScale', 0.0, 10, 0.1 ).onChange( this.matChanger )
    gui.add( guiObj, 'focusDistance', -1, 1, 0.00001 ).onChange( this.matChanger )
    gui.add( guiObj, 'focalLength', 0, 0.5, 0.0001 ).onChange( this.matChanger )
  }

  matChanger () {
    this.postProcess.dofEffect.bokehScale = guiObj.bokehScale
    this.postProcess.dofEffect.circleOfConfusionMaterial.focusDistance = guiObj.focusDistance
    this.postProcess.dofEffect.circleOfConfusionMaterial.focalLength = guiObj.focalLength
    // this.postProcess.bokehPass.uniforms[ 'focus' ].value = guiObj.focus
    // this.postProcess.bokehPass.uniforms[ 'aperture' ].value = guiObj.aperture * 0.00001
    // this.postProcess.bokehPass.uniforms[ 'maxblur' ].value = guiObj.maxblur
  }

  resize ({width, height}) {
    const pixelRatio = levelFPS.levelProperty.includes('RESOLUTION_LOW') ? .5 : levelFPS.levelProperty.includes('NO_RETINA') ? 1 : window.devicePixelRatio

    // Instance
    this.renderer.setSize(width, height)
    this.renderTarget.setSize(width, height)
    this.renderer.setPixelRatio(pixelRatio)

    // Post process
    this.postProcess.composer.setSize(width, height)
    // this.postProcess.composer.setPixelRatio(pixelRatio)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  update () {
    // console.log(this.renderer)
    if (this.isPostprocess) {
      this.postProcess.composer.render()
    }
    else {
      this.renderer.render(this.scene, this.camera)
    }

    if (this.stats) {
      this.stats.afterRender()
    }
  }

  get canvas () {
    return this.renderer.domElement
  }

  get bokehTarget () {
    return this.postProcess.dofEffect.target
  }

  set bokehTarget (value) {
    this.postProcess.dofEffect.target = value
  }

  set bokehDist (value) {
    this.postProcess.dofEffect.circleOfConfusionMaterial.focusDistance = value
    guiObj.focusDistance = value
  }
  
  get bokehDist () {
    return this.postProcess.dofEffect.circleOfConfusionMaterial.focusDistance
  }

  get maxBlur () {
    return this.postProcess.dofEffect.bokehScale
  }

  set maxBlur (value) {
    this.postProcess.dofEffect.bokehScale = value
    guiObj.bokehScale = value
  }

  // bigger bokeh zone = more blur
  set bokehZone (value) {
    this.postProcess.dofEffect.circleOfConfusionMaterial.focalLength = value
    guiObj.focalLength = value
  }
  // smaller bokeh zone = less blur
  get bokehZone () {
    return this.postProcess.dofEffect.circleOfConfusionMaterial.focalLength
  }

  destroy () {
    this.renderer.renderLists.dispose()
    this.renderer.dispose()
    this.renderTarget.dispose()
    this.postProcess.composer.renderTarget1.dispose()
    this.postProcess.composer.renderTarget2.dispose()
  }
}
